.image-background {
  border-radius: 20px;
  width: 100%;
  height: auto;
  overflow: hidden;
  backdrop-filter: blur(70px);
  -webkit-backdrop-filter: blur(70px);
  object-fit: contain;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin: 0;
  min-width: 100px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.image-background::-webkit-scrollbar {
  width: 0 !important;
}
