table.fc-col-header {
  background-color: #262b40;
}

a.fc-col-header-cell-cushion {
  color: #fcfcfc;
}

.fc .fc-daygrid-day-frame {
  height: 100px;
}
