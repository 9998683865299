@media screen and (max-width: 480px) {
  #timeline-main-wrapper {
    padding: 0px !important;
  }

  #timeline-main-wrapper button {
    display: none;
  }

  #timeline-main-wrapper > div {
    padding: 0px !important;
  }

  .timeline-outlinestyles {
    display: none;
  }
  .vertical-item-row .timeline-item-title {
    display: none;
  }
  .timeline-verticalstyles: {
    padding: 0px !important;
  }
}
